@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  min-height: 100vh;
  font-family: 'Nunito Sans';
}

.chakra-alert button {
  top: 12px
}

body{
  &::-webkit-scrollbar {
    display: none;
}
}

.strike-off {
  text-decoration: line-through;
}