.toastStyles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100% !important;
  
    .Toastify {
      &__toast--default {
        align-items: center;
        background: black !important;
        border-radius: 4px;
        color: white;
  
        display: inline-flex;
        margin: 0 auto 4px;
        max-width: 360px !important;
        min-height: 40px !important;
        padding: 8px 16px !important;
        top: 72px !important;
      }
  
      &__toast-body {
        font-family: 'Nunito Sans', sans-serif;
        font-size: 16px;
        margin: 0;
      }
  
      &__close-button {
        align-self: center;
        height: 100%;
        opacity: 0.9;
        padding-left: 8px;
  
        svg {
          fill: white;
        }
      }
    }
  }
  
  .snackBar {
    background: var(--text-light) !important;
    font-size: 16px;
    padding: 16px !important;
  
    &.customSnackBarPosition {
      bottom: 0;
      top: auto !important;
    }
  
    &.noSnackBarPosition {
      bottom: 16px;
      margin-top: 70px !important;
      top: auto !important;
    }
  
    &.bottom-center {
      bottom: 120px;
      top: auto !important;
  
      &.above-fab {
        bottom: 84px;
      }
    }
  
    .Toastify__toast-body {
      .grid {
        align-items: center;
        font-size: 16px;
        grid-column-gap: 12px;
        grid-template-columns: 1fr 40px;
      }
  
      button {
        color: white;
        font-size: 16px;
      }
    }
  
    // Snack bar uses 'Got it' action instead of close
    .Toastify__close-button {
      display: none;
    }
  }
  